// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_paragraph__zCPSU {
  display: flex;
  font-size: 30px;
  line-height: 1;
  font-family: "Rubik", sans-serif;
  color: #d9d9d9;
  flex-wrap: wrap;
  margin: 0;
}
.style_paragraph__zCPSU .style_word__w4eDO {
  position: relative;
  margin-right: 12px;
  margin-top: 12px;
}
.style_paragraph__zCPSU .style_word__w4eDO .style_shadow__1k8Xi {
  position: absolute;
  opacity: 20%;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,cAAA;EACA,gCAAA;EACA,cAAA;EACA,eAAA;EACA,SAAA;AACF;AACE;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACE,kBAAA;EACA,YAAA;AACN","sourcesContent":[".paragraph {\n  display: flex;\n  font-size: 30px;\n  line-height: 1;\n  font-family: \"Rubik\", sans-serif;\n  color: #d9d9d9;\n  flex-wrap: wrap;\n  margin: 0;\n\n  .word {\n    position: relative;\n    margin-right: 12px;\n    margin-top: 12px;\n\n    .shadow {\n      position: absolute;\n      opacity: 20%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `style_paragraph__zCPSU`,
	"word": `style_word__w4eDO`,
	"shadow": `style_shadow__1k8Xi`
};
export default ___CSS_LOADER_EXPORT___;
